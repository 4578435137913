.footer-container{
    background-color: #242424;
    padding: 0.2rem 0 1rem 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 

.social-icon-link{
    color:#fff;
    font-size:24px;
}

.social-media {
    max-width: 1000px;
    width:100%;
}

.social-media-wrap{
   display:flex;
   justify-content: space-between;
   align-items: center;
   width:90%;
   max-width: 1000px;
   margin: 40px auto 0 auto;
}

.social-icons{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;/*este los separa*/

}

.website-rights {
    color:#fff;
    margin-bottom: 16px;
    font-size:1.2rem;
}

.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
.boton-Instalar{
    display:flex;
    font-size:  22px;
    color: #fff;
    padding: 0.5rem 1rem;

    

}

@media screen and (max-width:820px) {
    .social-media-wrap{
        flex-direction: column;
        font-size: 12px;
    }
    .website-rights{
        font-size: 12px;
    }
}