.ubicacion{
    padding:4rem;
    background:#fff;
}

h1{
    text-align: center;
    padding: 0.5em;
    color: black;
}

.hola{
    padding: 1rem;
    text-align: center;
}

.ubicaciones-btns{
    justify-content:center;
    padding: 1rem;
    text-align: center;
}

.ubicacion-btn{
    
    background-color:rgba(255,255,255,0.1); 
    backdrop-filter: blur(20px) !important;
    /*color: #242424;*/
    border: 1px solid #242424;
    border-radius:5px;
    padding: 12px 26px;
    font-size: 20px;
    width: 70%;

}



.ubicaciones__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width:90%;
    margin:0 auto;
  }

.ubicaciones__wrapper {
    position:relative;
    margin: 50px 0 45px;

}

.ubicaciones__items {
    margin-bottom:24px;

}
/*desde aca hacia abajo los css de servicios items*/

.ubicacion__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
}

.ubicacion__item__link{
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.ubicacion__item__pic-wrap{
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.ubicacion__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}


.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}
  

.ubicacion__item__img{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.ubicacion__item__img:hover {
    transform: scale(1.1);
}

.ubicacion__item__info {
    padding: 20px 30px 30px;
}
  
.ubicacion__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

 
@media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
}
  
@media only screen and (min-width: 1024px) {
    .ubicaciones__items {
      display: flex;
    }

}
  
@media only screen and (max-width: 1024px) {
    .ubicacion__item {
      margin-bottom: 2rem;
    }
}