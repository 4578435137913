*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
   /* font-family: 'PT Sans', sans-serif;*/

}

.home,
/*.services,*/
.products,
.sign-up {
 display: flex;
 height: 90vh;
 align-items:center;
 justify-content: center;
 font-size: 3rem;

}

.services {
   /* background-image: url('/images/img-2.jpg');*/
   /* background-position: center;*/
   /* background-size: cover;*/
   /* background-repeat: no-repeat;*/
    color:black;
    font-size: 16px;
  }
  