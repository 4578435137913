.modal{
    position: fixed;
    z-index: 999;
    top: 0;
    left:0;
    width: 100%;
    min-height: 100vh;
    background-color:rgba(0,0,0,.75);
    display: none;/*si pongo none no se va a mostrar el modal*/
    justify-content: center;
    align-items: center;
}

.modal.is-open {
    display: flex;
}

.modal-container {
    
    padding: 1rem;
    position: relative;
    min-width: 320px;/*aca va el tamaño de lo de adentro*/
    max-width:480px;
    min-height:200px;
    max-height:800px;
    overflow-y: auto;/*para que aparesca el scroll por si es muy grande la imagen*/
    background-color:#fff;
}

.modal-close {
    position:absolute;
    top: 1rem;
    right: 1rem;
}

h1 {
    padding:0.5 rem;

}

.iconos {
    padding:0.3rem;
    text-align:center;
    color:black;
    
    

}